import React, { useEffect, useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './publicContent.module.scss';
import { get } from '../../utils/apiClient';
import WindowDimensions from 'components/Universal/WindowDimensions';
import { formatMoney } from 'utils';

export default function HomePageFeature() {
  const [homePageFeatures, setHomePageFeatures] = useState([]);

  const fetchHomePageFeature = async () => {
    const response = await get('/publicContent/getHomePageCarousel');
    if (response.success) {
      return setHomePageFeatures(response.result);
    }
  };

  useEffect(() => {
    fetchHomePageFeature();
  }, []);

  //Column display for mobile
  const dimensions = WindowDimensions();

  let carouselDisplay = dimensions.width > 480 ? 20 : 50; 
  return (
    <>
      {homePageFeatures && homePageFeatures.length >= 4 &&
        <>
          <div className={styles["public__content__container"]}>
            <h3 className={`${styles["carousel__header"]} ease-up`}>Latest listings</h3>
            <Carousel
              showStatus={false}
              showArrows={true}
              centerSlidePercentage={carouselDisplay}
              centerMode
              autoPlay={homePageFeatures.length > 5 ? true : false}
              infiniteLoop={true}
              showIndicators={false}
              showThumbs={false}
              dynamicHeight={false}
              interval={3000}
              swipeable={true}
              className={'ease-up'}>
              {homePageFeatures?.map((card, index) => (
                <a key={index} href={card.url}>
                  <div className={styles["home_page_feature_container_in_carousel"]} key={index}>
                    <div className={styles["home_page_feature_content__detail"]}>
                      <Image src={card.logo} alt="logo_file" style={{width: 'auto', maxWidth:'60%', maxHeight: '13%'}}/>

                      {/* Only render fund and share types where dimensions > 768px */}
                      <p className={styles["fund-type"]}>{card.productType}</p>
                      <div className={styles["share-type__container"]}>
                        <p className={styles["share-type"]}>{card.productName}</p>
                      </div>
                      <div className={styles["business-name__container"]}>
                        <p className={styles["business-name"]}>{card.businessName}</p>
                      </div>
                    <div className={styles["carousel-bottom"]}>
                      {
                        dimensions.width > 1299 ? (

                          /**
                           * Render carousel with the label
                           * 
                           * Wide screen issue: carousel-bottom__label stacks, causing broken ui
                           * Work around, set total column widths to 14 (usually 16)
                           */
                          <Grid columns={16}>
                            <Grid.Column width={12}>
                              <Grid columns={1} style={{height: '220px'}}>
                                <Grid.Row className={styles["carousel-bottom__grid__row"]}>
                                  <Grid.Column>
                                    <div className={styles["carousel-bottom__field"]}>Listed value</div>
                                    <p className={styles["carousel-bottom__value"]}>{card.productMarketCap === 'Private' ? card.productMarketCap : formatMoney(card.productMarketCap, 3, {showCents: false,  emptyLabel: '-'})}</p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={styles["carousel-bottom__grid__row"]}>
                                  <Grid.Column className={styles["carousel-bottom__opento"]}>
                                    <div className={styles["carousel-bottom__field"]}>Open to</div>
                                    <p className={styles["carousel-bottom__value"]}>{card.productAccess}</p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={styles["carousel-bottom__grid__row"]}>
                                  <Grid.Column className={styles["carousel-bottom__openstatus"]}>
                                    <div className={styles["carousel-bottom__status"]}>
                                      {card.auctionStatus === 'Open' && "Open now"}
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                            {card.productMarket === 'Public' ?
                              <Grid.Column width={2} className={styles["carousel-bottom__label__blue"]}>
                                <p className={styles["carousel-bottom__label__text"]}>{card.productMarket}</p>
                              </Grid.Column>
                              : card.productMarket === 'Wholesale' ?
                              <Grid.Column width={2} className={styles["carousel-bottom__label__orange"]}>
                                <p className={styles["carousel-bottom__label__text"]}>{card.productMarket}</p>
                              </Grid.Column>
                              :
                              <Grid.Column width={2} className={styles["carousel-bottom__label"]}>
                                <p className={styles["carousel-bottom__label__text"]}>{card.productMarket}</p>
                              </Grid.Column>
                            }
                          </Grid>
                        )
                        : (
                          
                          // Render carousel without label
                          <div className={styles["no-label__container"]}>
                            <div className={styles["total-value__container"]}>
                              <div className={styles["carousel-bottom__field"]}>Listed value</div>
                              <p className={styles["carousel-bottom__value"]}>{card.productMarketCap === 'Private' ? card.productMarketCap : formatMoney(card.productMarketCap, 3, {showCents: false,  emptyLabel: '-'})}</p>
                            </div>
                            <div className={styles["open-to__container"]}>
                              <div className={styles["carousel-bottom__field"]}>Open to</div>
                              <p className={styles["carousel-bottom__value"]}>{card.productAccess}</p>
                            </div>
                            <div className={styles["open-now__container"]}>
                              <div className={styles["carousel-bottom__status"]}>
                                {card.auctionStatus === 'Open' && "Open now"}                           
                              </div>
                            </div>
                          </div>
                        )
                      }
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </Carousel>        
          </div>
        </>
      }
       {!homePageFeatures || homePageFeatures.length < 4 &&
       // Include a spacer if there are no features
       <div className={styles["public__content__spacer"]}></div>
       }
    </>
  );
}
